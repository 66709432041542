/* Typography */

.custom-block-title {
  @apply text-3xl font-bold tracking-normal;
}
.custom-block-big-title {
  @apply text-5xl xl:text-6xl font-bold tracking-normal;
}
.custom-block-subtitle {
  @apply text-xs text-customSecondary  tracking-wider font-bold uppercase;
}
.custom-content-title {
  @apply text-lg font-bold tracking-normal;
}
.custom-content-text {
  @apply text-base leading-relaxed;
}
.custom-content-text-gray {
  @apply text-gray-400 text-base;
}

/* Backgrounds */

.custom-bg-dark1 {
  @apply bg-customDarkBg1  fill-customDarkBg1;
}
.custom-bg-dark2 {
  @apply bg-customDarkBg2  fill-customDarkBg2;
}
.custom-bg-dark3 {
  @apply bg-customDarkBg3  fill-customDarkBg3;
}
body,
html {
  @apply bg-customDarkBg2;
}

/* Borders */

.custom-border-gray {
  @apply border border-solid border-[rgb(255,255,255,0.15)];
}
.custom-border-gray-darker {
  @apply border border-solid border-[rgb(255,255,255,0.07)];
}

/* Buttons */

.custom-button-colored {
  @apply rounded-lg font-bold bg-customPrimary text-white flex justify-center items-center hover:bg-[#7274f3] cursor-pointer transition;
}

/* Navbar */

.navbar-link {
  @apply text-[#555555] lg:text-base text-xl leading-6 mr-4 ml-4   2xl:mr-6 2xl:ml-6 cursor-pointer font-normal lg:font-medium hover:scale-110 transition duration-300 h-full pt-2;
}

/* Preventing FOUC */
@font-face {
  font-display: var(--fontsource-Inter-font-display, optional);
}

/* Stepper */
.step-item {
    @apply relative flex flex-col justify-center items-center w-36;
  }
.step-item:not(:first-child):before {
    @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
}
.step {
  @apply w-10 h-10 flex items-center justify-center z-10 relative bg-slate-700 rounded-full font-semibold text-white;
}
.active .step {
  @apply bg-sky-600;
}
.complete .step {
  @apply bg-green-600;
}
.complete p {
  @apply text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-green-600;
}