/* Hero */

.custom-shape-divider-bottom-1665343298 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.custom-shape-divider-bottom-1665343298 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 200px;
}
.hero-title-gradient {
  background: -webkit-linear-gradient(white, #b4add1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Features Top */

.custom-shape-divider-bottom-1665696614 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.custom-shape-divider-bottom-1665696614 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 127px;
  transform: rotateY(180deg);
}

/* Features Bottom */

.custom-shape-divider-top-1665696661 {
  overflow: hidden;
  line-height: 0;
}
.custom-shape-divider-top-1665696661 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 127px;
  transform: rotateY(180deg);
}
